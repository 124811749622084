import intlTelInput from 'intl-tel-input/build/js/intlTelInputWithUtils';
const { api } = require('../../libs/axios.lib');
const { notify } = require('../../utils/notify.utils');

const form = document.querySelector('.scalping-info-form');

if (form) {
  const formBtn = form.querySelector('#submit-scalping-info');
  const nameInput = form.querySelector('#scalping-info-name');
  const phoneInput = form.querySelector('#scalping-info-phone');

  formBtn.addEventListener('click', async (e) => {
    e.preventDefault();
    try {
      const iti = intlTelInput.getInstance(phoneInput);
      formBtn.classList.add('is-loading');

      const name = nameInput.value;
      const phone = iti.getNumber();

      const newContact = await api.post('/contact', {
        name,
        phone,
      });

      const newLead = await api.post('/lead', {
        name: 'Scalping info',
        phone,
        pipeline_id: 8243187,
      });

      await api.post('/lead-contact-link', {
        lead_id: newLead.data._embedded.leads[0].id,
        contact_id: newContact.data._embedded.contacts[0].id,
      });

      await api.post('/lead/user-data', {
        lead_id: newLead.data._embedded.leads[0].id,
        link: window.location.href,
        name: name,
        phone: phone,
        tariff: '-',
      });

      nameInput.value = '';
      phoneInput.value = '';

      window.gtag('event', 'scalping-info', {
        phone,
        name,
      });

      notify('Request sent successfully!', 'info');

      window.open('/thank-you', '_self');
    } catch (error) {
      console.log(error);
      notify('Error!', JSON.stringify(error));
    } finally {
      formBtn.classList.remove('is-loading');
    }
  });
}
